import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import TeamArea from '../containers/leadership/team-area'
import CTAArea from "../containers/global/cta-area/section-one";
import TeamListArea from '../containers/leadership/team-list-area'
import ContactArea from '../containers/global/contact-area/contact-one'

const LeadershipPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Our team" />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Our team"
    />
    <main className="site-wrapper-reveal">
      <TeamArea />
      {/* <TeamListArea/> */}
      {/* <ContactArea/> */}
      <CTAArea email="info@evolveadvisors.co.za" />
    </main>
    <Footer />
  </Layout>
);

  export default LeadershipPage